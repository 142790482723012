import { useContext, useEffect, useState } from 'react';
import { ChartCanvasContext } from 'react-financial-charts';
import { useDispatch } from 'react-redux';
import { setSelectedCriteriaForAnalysis } from 'store/analysisSlice';

const ContextMenu = () => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const chartCanvasContext = useContext(ChartCanvasContext);
  const dispatch = useDispatch();

  const listener = (type, ...rest) => {
    switch (type) {
      case 'contextmenu': {
        setShowContextMenu(true);
        const [{ currentItem }] = rest;
        dispatch(
          setSelectedCriteriaForAnalysis({
            from: currentItem.originalTimestamp,
            to: currentItem.originalTimestamp,
          })
        );
        break;
      }
      default:
    }
  };

  useEffect(() => {
    const { subscribe } = chartCanvasContext;

    subscribe('contextmenu', { listener });
  }, []);

  return showContextMenu && <div>Get more details</div>;
};

export default ContextMenu;
