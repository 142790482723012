import { fetcher } from '../fetch';

export const getStockData = async (payload) => {
  return await fetcher('/api/stock', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
};

export const getHaltedTickers = async () => {
  return await fetcher('/api/stock/halted', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getBidAsk = async (symbol, from, to) => {
  return await fetcher(`/api/${symbol}/quotes?start=${from}&end=${to}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getHaltsSSE = async () => {
  return new EventSource(`/api/real-time/halts`);
};

export const getHighVolumeDailyBars = async (symbol) => {
  return await fetcher(`/api/${symbol}/get-bars`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getStockFundamentals = async (symbol) => {
  return await fetcher(`/api/${symbol}/fundamentals`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getStockFilings = async (symbol) => {
  return await fetcher(`/api/${symbol}/filings`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};
