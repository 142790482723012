import { localTimezoneOffsetInMs } from '@/lib/utils';
import { TIMEFRAMES } from '@/lib/utils/constants';
import { IChartApi, CrosshairMode } from 'lightweight-charts';
import moment from 'moment-timezone';

const createOHLCVTooltipHTML = ({
  open,
  high,
  low,
  close,
  volume,
  date,
  timeframe,
}) => {
  // Format numbers and date for better readability

  const formattedDate =
    timeframe === TIMEFRAMES.DAYS_1
      ? moment(date).format('MM-DD-YYYY')
      : new Date(date + localTimezoneOffsetInMs).toLocaleTimeString();
  const formattedVolume = volume.toLocaleString();

  return `
      <div style="
        background-color: rgba(0, 0, 0, 0.7); 
        color: white; 
        padding: 8px; 
        border-radius: 4px;
        font-family: Arial, sans-serif;
        font-size: 12px;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
      ">
        <div>${formattedDate}</div>
        <div><strong>Open:</strong> ${open.toFixed(2)}</div>
        <div><strong>High:</strong> ${high.toFixed(2)}</div>
        <div><strong>Low:</strong> ${low.toFixed(2)}</div>
        <div><strong>Close:</strong> ${close.toFixed(2)}</div>
        <div><strong>Volume:</strong> ${formattedVolume}</div>
      </div>
    `;
};

export class CustomTooltipPrimitive {
  constructor(
    chart,
    candlestickSeries,
    histogramSeries,
    options = {},
    timeframe
  ) {
    this.chart = chart;
    this.candlestickSeries = candlestickSeries;
    this.histogramSeries = histogramSeries;
    this.options = {
      lineColor: 'rgba(0, 0, 0, 0.2)',
      tooltipBgColor: 'white',
      tooltipTextColor: '#131722',
      borderRadius: '4px',
      ...options,
    };
    this.timeframe = timeframe;

    this._createTooltipElement();
    this._setupCrosshairMode();

    this.chart.subscribeCrosshairMove(this._onCrosshairMove.bind(this));
  }

  _createTooltipElement() {
    const tooltip = document.createElement('div');
    tooltip.style.position = 'absolute';
    tooltip.style.opacity = '0';
    // tooltip.style.backgroundColor = this.options.tooltipBgColor;
    tooltip.style.borderRadius = this.options.borderRadius;
    tooltip.style.padding = '10px';
    // tooltip.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.2)';
    tooltip.style.color = this.options.tooltipTextColor;
    tooltip.style.pointerEvents = 'none';
    tooltip.style.zIndex = 1;

    // document.body.appendChild(tooltip);
    this.chart.chartElement().parentElement.appendChild(tooltip);

    this.tooltip = tooltip;
  }

  _setupCrosshairMode() {
    this.chart.applyOptions({
      crosshair: {
        mode: CrosshairMode.Normal,
      },
    });
  }

  _onCrosshairMove(param) {
    const logical = param.logical;
    const seriesData = param.seriesData;

    if (!logical || !seriesData) {
      this.tooltip.style.opacity = '0';
      return;
    }

    const candlestickData = seriesData.get(this.candlestickSeries);
    const histogramData = seriesData.get(this.histogramSeries);

    if (!candlestickData && !histogramData) {
      this.tooltip.style.opacity = '0';
      return;
    }

    const { open, high, low, close, time } = candlestickData;
    const { value: volume } = histogramData || { value: 0 };

    const tooltipHTML = createOHLCVTooltipHTML({
      open,
      high,
      low,
      close,
      volume,
      date: time * 1000, // example date in milliseconds
      timeframe: this.timeframe,
    });

    // Insert the HTML into your tooltip element
    this.tooltip.innerHTML = tooltipHTML;
    this.tooltip.style.opacity = '1';
    this.tooltip.style.left = `${param.point.x + 10}px`;
    this.tooltip.style.top = `${param.point.y + 10}px`;
  }

  destroy() {
    this.chart.unsubscribeCrosshairMove(this._onCrosshairMove.bind(this));
    if (this.tooltip) {
      this.chart?.chartElement()?.parentElement?.removeChild(this.tooltip);
    }
  }
}
