import { Box } from '@mui/material';
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import { noop } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { SessionHighlighting } from '../Chart/plugins/session-highlighting/session-highlighting';
import { TooltipPrimitive } from '../Chart/plugins/tooltip/tooltip';
import { subscribeForTrades, unsubscribeFromTrades } from '@/lib/stream';
import { useSelector } from 'react-redux';
import { selectStreamTrades } from 'store/streamSlice';
import { CustomTooltipPrimitive } from '../Chart/plugins/custom-tooltip/tooltip';
import { marketHoursInLocalTZ } from '@/lib/utils/date';
import { DeltaTooltipPrimitive } from '../Chart/plugins/delta-tooltip/delta-tooltip';

const UP_COLOR = '#26a69a';
const DOWN_COLOR = '#ef5350';

const localTimezoneOffset = new Date().getTimezoneOffset() * 60;

const deltaTooltipPrimitive = new DeltaTooltipPrimitive({
  lineColor: 'rgba(0, 0, 0, 0.2)',
});

const timeZoned = (data) =>
  data.map((d) => ({
    ...d,
    time: d.time - localTimezoneOffset,
  }));

export const LightChart = (props) => {
  const {
    data,
    colors: {
      backgroundColor = 'white',
      lineColor = '#2962FF',
      textColor = 'black',
      areaTopColor = '#2962FF',
      areaBottomColor = 'rgba(41, 98, 255, 0.28)',
    } = {},
    symbol,
    attachChartInstance = noop,
    deltaTooltip = false,
    timeframe = '1Min',
  } = props;

  const previousSymbol = useRef(null);
  const trades = useSelector(selectStreamTrades);
  const timezonedData = timeZoned(data);
  const candleSeriesRef = useRef(null);
  const volumeSeriesRef = useRef(null);
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);
  const currentCandleStick = useRef({
    open: 0,
    high: 0,
    low: 0,
    close: 0,
  });

  const chartContainerRef = useRef();

  const resetCurrentCandleStick = () => {
    const _candle = {
      ...currentCandleStick.current,
    };
    _candle.close = 0;
    _candle.open = 0;
    _candle.high = 0;
    _candle.low = 0;

    currentCandleStick.current = _candle;
  };

  const updatedCurrentCandleStick = (price) => {
    const _candle = {
      ...currentCandleStick.current,
    };
    const _time = Math.floor(Date.now() / 1000 / 60) * 60;

    if (currentCandleStick.current.time !== _time) {
      _candle.close = 0;
      _candle.open = 0;
      _candle.high = 0;
      _candle.low = 0;
    }

    if (!_candle.open) {
      _candle.open = price;
    }

    if (!_candle.close) {
      _candle.close = price;
    }

    if (!_candle.high) {
      _candle.high = price;
    }

    if (!_candle.low) {
      _candle.low = price;
    }

    if (price > _candle.high) {
      _candle.high = price;
    }

    if (price < _candle.low) {
      _candle.low = price;
    }

    _candle.close = price;

    _candle.time = _time;

    currentCandleStick.current = _candle;

    return _candle;
  };

  useEffect(() => {
    const handleResize = () => {
      chartRef.current.applyOptions({
        width: chartContainerRef.current.clientWidth,
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
      });
    };

    chartRef.current = createChart(chartContainerRef.current, {
      crosshair: {
        // Change mode from default 'magnet' to 'normal'.
        // Allows the crosshair to move freely without snapping to datapoints
        mode: CrosshairMode.Normal,
      },
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
      priceScaleId: 'left',
      scaleMargins: {
        top: 0.9, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    if (attachChartInstance) {
      attachChartInstance(chartRef.current);
    }

    const candlestickSeries = chartRef.current.addCandlestickSeries({
      upColor: UP_COLOR,
      downColor: DOWN_COLOR,
      borderVisible: false,
      wickUpColor: UP_COLOR,
      wickDownColor: DOWN_COLOR,
    });

    chartRef.current.applyOptions({
      watermark: {
        visible: true,
        fontSize: 64,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(0, 112, 243, 0.2)',
        text: symbol,
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
      priceScaleId: 'left',
    });

    candlestickSeries.setData(timezonedData);
    candleSeriesRef.current = candlestickSeries;

    const volumeSeries = chartRef.current.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: 'left', // set as an overlay by setting a blank priceScaleId
      // set the positioning of the volume series
      scaleMargins: {
        top: 0.7, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });
    volumeSeries.priceScale('left').applyOptions({
      scaleMargins: {
        top: 0.9, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    volumeSeries.setData(
      timezonedData.map(({ volume, time, close, open }) => ({
        time,
        value: volume,
        color: close > open ? UP_COLOR : DOWN_COLOR,
      }))
    );

    volumeSeriesRef.current = volumeSeries;

    // Pre and post market session highlighting
    const sessionHighlighter = (time) => {
      const date = new Date(time * 1000);

      const hours = date.getUTCHours(); // 0 - 23
      const minutes = date.getUTCMinutes(); // 0 - 59

      if (
        hours >= marketHoursInLocalTZ.preMarketOpen &&
        hours <= marketHoursInLocalTZ.postMarketClose
      ) {
        if (
          hours < marketHoursInLocalTZ.regOpen ||
          (hours === marketHoursInLocalTZ.regOpen && minutes <= 30)
        ) {
          // Premarket 4:00 - 9:30 EST
          return 'rgba(255, 152, 1, 0.08)';
        }

        if (
          hours >= marketHoursInLocalTZ.regClose &&
          hours <= marketHoursInLocalTZ.regClose
        ) {
          // Post market hours 16:00 - 20:00 EST
          return 'rgba(41, 98, 255, 0.08)';
        }
      }
    };

    const sessionHighlighting = new SessionHighlighting(sessionHighlighter);
    candlestickSeries.attachPrimitive(sessionHighlighting);

    chartRef.current.timeScale().fitContent();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartRef.current.remove();
    };
  }, [
    symbol,
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);

  useEffect(() => {
    if (trades[previousSymbol.current]) {
      if (candleSeriesRef.current) {
        try {
          candleSeriesRef.current.update(
            updatedCurrentCandleStick(trades[previousSymbol.current])
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }, [trades]);

  //Attach tooltip
  useEffect(() => {
    if (candleSeriesRef.current && chartRef.current) {
      if (deltaTooltip) {
        //Lock scroll
        chartRef.current.applyOptions({
          handleScroll: false,
        });

        if (tooltipRef.current) {
          tooltipRef.current.destroy();
        }

        candleSeriesRef.current.attachPrimitive(deltaTooltipPrimitive);
      } else {
        //Unlock scroll
        chartRef.current.applyOptions({
          handleScroll: true,
          crosshair: {
            mode: CrosshairMode.Normal,
            vertLine: {
              visible: true,
              labelVisible: true,
            },
            horzLine: {
              visible: true,
              labelVisible: true,
            },
          },
        });
        if (deltaTooltipPrimitive) {
          candleSeriesRef.current.detachPrimitive(deltaTooltipPrimitive);
        }
        // Create and attach the custom tooltip plugin
        tooltipRef.current = new CustomTooltipPrimitive(
          chartRef.current,
          candleSeriesRef.current,
          volumeSeriesRef.current,
          {
            tooltipBgColor: 'rgba(255, 255, 255, 0.9)',
            tooltipTextColor: '#000',
            borderRadius: '8px',
          },
          timeframe
        );
      }
    }
  }, [deltaTooltip, symbol, timeframe]);

  // useEffect(() => {
  //   if (trades[previousSymbol.current]) {
  //     if (chartRef.current) {
  //       chartRef.current.update(
  //         updatedCurrentCandleStick(trades[previousSymbol.current])
  //       );
  //     }
  //   }
  // }, [bars]);

  useEffect(() => {
    if (symbol) {
      if (previousSymbol.current !== symbol) {
        previousSymbol.current &&
          unsubscribeFromTrades([previousSymbol.current]);
        resetCurrentCandleStick();
        subscribeForTrades([symbol]);
        previousSymbol.current = symbol;
      }
    }
  }, [symbol]);

  // Clean up
  useEffect(() => {
    return () => {
      previousSymbol.current && unsubscribeFromTrades([previousSymbol.current]);
      previousSymbol.current = null;
    };
  }, []);

  return <Box sx={{ height: '90%' }} ref={chartContainerRef} />;
};
