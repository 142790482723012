import {
  head,
  GenericChartComponent,
  plotDataLengthBarWidth,
} from '@react-financial-charts/core';
import moment from 'moment-timezone';
import * as React from 'react';

/**
 * A `BarSeries` component.
 */
export class SessionBG extends React.Component {
  static defaultProps = {
    baseAt: (xScale, yScale) => head(yScale.range()),
    clip: true,
    swapScales: false,
    width: plotDataLengthBarWidth,
    widthRatio: 0.8,
    selectCanvas: (canvases) => canvases.bg,
  };

  render() {
    const { clip, selectCanvas } = this.props;

    return (
      <GenericChartComponent
        clip={clip}
        canvasToDraw={selectCanvas}
        canvasDraw={this.drawOnCanvas}
        drawOn={['pan']}
      />
    );
  }

  drawOnCanvas = (ctx, moreProps) => {
    const bgRects = this.getBars(moreProps);

    const { height } = this.props;

    bgRects.forEach((d) => {
      ctx.fillStyle = 'rgba(181, 213, 181, 0.4)';
      ctx.fillRect(d.x, d.y, d.width, height);
    });
  };

  getBars = (moreProps) => {
    const { xScale, xAccessor, plotData } = moreProps;

    const firstDataPoint = plotData[0];
    const lastDataPoint = plotData[plotData.length - 1];

    const allDates = plotData.reduce((acc, curr) => {
      const date = moment
        .tz(curr.date, 'America/New_York')
        .format('YYYY-MM-DD');

      if (acc.indexOf(date) < 0) {
        acc.push(date);
      }

      return acc;
    }, []);

    const bgRects = allDates
      .map((date, idx) => {
        const [regularOpen] = plotData.filter((d) => {
          return (
            moment(d.date).format('YYYY-MM-DD') === date &&
            moment(d.date).hours() === 9 &&
            moment(d.date).minutes() === 30
          );
        });

        if (regularOpen) {
          let dayStart = firstDataPoint;

          if (idx - 1 >= 0) {
            dayStart = plotData.filter((d) => {
              return (
                moment(d.date).format('YYYY-MM-DD') === date &&
                moment(d.date).hours() === 16 &&
                moment(d.date).minutes() === 0
              );
            });
          }

          const xValue = xAccessor(dayStart);
          const xStart = xScale(xValue);
          const xEnd = xScale(xAccessor(regularOpen));

          return {
            x: xStart,
            y: 0,
            width: xEnd - xStart,
          };
        } else {
          return undefined;
        }
      })
      .filter((d) => !!d);

    const lastDate = allDates[allDates.length - 1];
    const [regularClose] = plotData.filter((d) => {
      return (
        moment(d.date).format('YYYY-MM-DD') === lastDate &&
        moment(d.date).hours() === 16 &&
        moment(d.date).minutes() === 0
      );
    });

    const xValue = xAccessor(regularClose);
    const xStart = xScale(xValue);
    const xEnd = xScale(xAccessor(lastDataPoint));

    bgRects.push({
      x: xStart,
      y: 0,
      width: xEnd - xStart,
    });

    return bgRects;
  };
}
