export * from './date';
export * from './type';
export * from './stock';
export * from './number';

export const processedPayload = (payload) => {
  const _payload = { ...payload };
  delete _payload._id;
  return JSON.stringify(_payload);
};
