import moment from 'moment-timezone';
import { NY_TZ, TIME } from './constants';

function getHourDifferenceWithNY() {
  // Get the current time
  const now = new Date();

  // Get the local timezone offset in hours
  const localOffset = -now.getTimezoneOffset() / 60;

  // Create a formatter for the New York timezone
  const nyFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: NY_TZ,
    hour12: false,
    hour: '2-digit',
  });

  // Get the current hour in New York
  const nyHour = parseInt(nyFormatter.format(now), 10);

  // Calculate the difference in hours
  const hourDifference = nyHour - now.getHours();

  return hourDifference;
}

const _hourDifferenceWithNY = getHourDifferenceWithNY();

export const dateMMDDYYY = (_date) => {
  return moment.tz(new Date(_date), NY_TZ).format('MM-DD-YYYY');
};

export const dateYYYYMMDD = (_date) => {
  return moment.tz(new Date(_date), NY_TZ).format('YYYY-MM-DD');
};

export const timeHHMMSS = (_date) => {
  return moment.tz(new Date(_date), NY_TZ).format('HH:MM:SS');
};

export const timeHHmm = (_date) => {
  return moment.tz(new Date(_date), NY_TZ).format('HH:mm');
};

export const getPremarketOpenDateTime = (_date) => {
  return moment.tz(`${dateYYYYMMDD(_date)} 04:00`, NY_TZ).format();
};

export const getMarketOpenDateTime = (_date) => {
  return moment.tz(`${dateYYYYMMDD(_date)} 09:30`, NY_TZ).format();
};

export const getMarketCloseDateTime = (_date) => {
  return moment.tz(`${dateYYYYMMDD(_date)} 16:00`, NY_TZ).format();
};

export const getAfterHoursCloseDateTime = (_date) => {
  return moment.tz(`${dateYYYYMMDD(_date)} 20:00`, NY_TZ).format();
};

export const getCurrentDate = () => {
  return moment.tz(NY_TZ).format('YYYY-MM-DD');
};

export const getNextTimestamp = (timestamp, quantity, unit) => {
  switch (unit) {
    case TIME.MINUTES: {
      const minutes = moment(timestamp).minutes();

      return moment
        .tz(timestamp, NY_TZ)
        .minutes(minutes + quantity)
        .format();
    }

    case TIME.DATE: {
      const date = moment(timestamp);
      const nextDate = date.clone().add(quantity, 'days');

      return quantity > 0
        ? getAfterHoursCloseDateTime(nextDate)
        : getPremarketOpenDateTime(nextDate);
    }

    case TIME.YEAR: {
      const date = moment(timestamp);
      const nextDate = date.clone().add(quantity, 'years');

      return quantity > 0
        ? getAfterHoursCloseDateTime(nextDate)
        : getPremarketOpenDateTime(nextDate);
    }

    default:
      return timestamp;
  }
};

export const localTimezoneOffsetInMs =
  new Date().getTimezoneOffset() * 60 * 1000;

export const marketHoursInLocalTZ = {
  regOpen: 9 - _hourDifferenceWithNY,
  regClose: 16 - _hourDifferenceWithNY,
  preMarketOpen: 4 - _hourDifferenceWithNY,
  postMarketClose: 20 - _hourDifferenceWithNY,
};
