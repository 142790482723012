import * as React from 'react';
import { Chart, ChartCanvas } from '@react-financial-charts/core';
import { XAxis, YAxis } from '@react-financial-charts/axes';
import { discontinuousTimeScaleProviderBuilder } from '@react-financial-charts/scales';
import {
  BarSeries,
  CandlestickSeries,
  VolumeProfileSeries,
} from '@react-financial-charts/series';
import { withDeviceRatio, withSize } from '@react-financial-charts/utils';
import { change } from '@react-financial-charts/indicators';
import {
  CrossHairCursor,
  Cursor,
  HoverTooltip,
  Label,
  MouseCoordinateX,
  MouseCoordinateY,
  OHLCTooltip,
  ZoomButtons,
} from 'react-financial-charts';
import moment from 'moment-timezone';
import { numberWithCommas } from '@/lib/utils';
import ContextMenu from './ContextMenu';
import { SessionBG } from './SessionBG';

class FinancialChart extends React.Component {
  margin = { left: 0, right: 40, top: 0, bottom: 24 };
  xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    (d) => d.date
  );

  render() {
    const {
      crosshair,
      data: initialData,
      height,
      ratio,
      width,
      symbol,
      options = {},
      ...rest
    } = this.props;

    // const initialData = iData.map(parseData);
    const showVolumeProfile = options.volumeProfile;
    const absoluteChange = change()(initialData);

    const { data, xScale, xAccessor, displayXAccessor } =
      this.xScaleProvider(absoluteChange);

    const gridHeight = height - this.margin.top - this.margin.bottom;
    const barChartHeight = gridHeight / 4;
    const barChartOrigin = (_, h) => [0, h - barChartHeight];
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 100)]);
    const xExtents = [min, max];

    const { ...cursorProps } = rest;

    return (
      <ChartCanvas
        height={height}
        ratio={ratio}
        width={width}
        margin={this.margin}
        data={data}
        displayXAccessor={displayXAccessor}
        seriesName="Data"
        xScale={xScale}
        xAccessor={xAccessor}
        xExtents={xExtents}
      >
        <Chart
          id={1}
          height={barChartHeight}
          origin={barChartOrigin}
          yExtents={this.barChartExtents}
        >
          <BarSeries
            fillStyle={this.volumeColor}
            yAccessor={this.volumeSeries}
          />
          <ZoomButtons />
        </Chart>
        <Chart id={2} yExtents={this.yExtents}>
          <CandlestickSeries />
          {showVolumeProfile && <VolumeProfileSeries />}
          <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
          <YAxis ticks={4} showGridLines gridLinesStrokeStyle="#e0e3eb" />
          <MouseCoordinateX
            displayFormat={(_) => {
              return moment(_).format('HH:mm');
            }}
          />
          <MouseCoordinateY
            displayFormat={(_) => {
              return Number(_).toFixed(2);
            }}
          />
          <OHLCTooltip
            origin={[8, 16]}
            textFill={(d) => (d?.close > d?.open ? '#26a69a' : '#ef5350')}
          />
          <HoverTooltip
            tooltip={{
              content: ({ currentItem }) => ({
                x: moment(currentItem?.date).format('MM-DD-YYYY HH:mm'),
                y: [
                  {
                    label: 'Open',
                    value: currentItem?.open,
                  },
                  {
                    label: 'High',
                    value: currentItem?.high,
                  },
                  {
                    label: 'Low',
                    value: currentItem?.low,
                  },
                  {
                    label: 'Close',
                    value: currentItem?.close,
                  },
                  {
                    label: 'Volume',
                    value: numberWithCommas(currentItem?.volume),
                  },
                ],
              }),
            }}
          />
          <Label
            text={symbol}
            x={(width - this.margin.left - this.margin.right) / 2}
            y={(height - this.margin.top - this.margin.bottom) / 2}
          />
          <SessionBG height={height} />
        </Chart>
        {crosshair && <CrossHairCursor />}
        {!crosshair && <Cursor {...cursorProps} />}
        <ContextMenu />
      </ChartCanvas>
    );
  }

  barChartExtents = (data) => {
    return data.volume;
  };

  yExtents = (data) => {
    return [data.high, data.low];
  };

  volumeSeries = (data) => {
    return data.volume;
  };

  volumeColor = (data) => {
    return data.close > data.open
      ? 'rgba(38, 166, 154, 0.3)'
      : 'rgba(239, 83, 80, 0.3)';
  };
}

const MemoizedFinancialChart = React.memo(FinancialChart);

export default withSize({ style: { flex: 1, height: '100%' } })(
  withDeviceRatio()(MemoizedFinancialChart)
);
