import { useRef, useEffect } from 'react';
import styles from './Price.module.css';

const Price = ({ val }) => {
  const currentPrice = useRef(val);
  const currentClass = useRef('');

  currentClass.current =
    val - currentPrice.current > 0
      ? styles.green
      : val - currentPrice.current < 0
      ? styles.red
      : currentClass.current;

  useEffect(() => {
    currentPrice.current = val;
  }, [val]);

  return <span className={currentClass.current}>{val}</span>;
};

export default Price;
