import { NY_TZ } from './constants';

export const convertAlpacaDataToFinancialChartData = (data) => {
  return data.map(
    ({ Timestamp, OpenPrice, HighPrice, LowPrice, ClosePrice, Volume }) => ({
      // date: new Date(
      //   new Date(Timestamp).toLocaleString('en-US', { timeZone: NY_TZ })
      // ),
      date: new Date(Timestamp),
      open: OpenPrice,
      high: HighPrice,
      low: LowPrice,
      close: ClosePrice,
      volume: Volume,
      originalTimestamp: Timestamp,
    })
  );
};

export const convertDataToNYTZ = (data) => {
  return data.map(({ date, open, high, low, close, volume }) => ({
    date: new Date(new Date(date).toLocaleString('en-US', { timeZone: NY_TZ })),
    open,
    high,
    low,
    close,
    volume,
  }));
};

export const calculateGapup = ({ OpenPrice, PreviousClose }) => {
  if (PreviousClose) {
    return (
      (((OpenPrice - PreviousClose) / PreviousClose) * 100)?.toFixed(2) || 0
    );
  }

  return 0;
};

export const calculateMorningGapup = ({ OpenPrice, HighPrice }) => {
  return (((HighPrice - OpenPrice) / OpenPrice) * 100)?.toFixed(2) || 0;
};

export const processDailyBarData = (data) => {
  const results = {};
  let gapSum = 0;
  let morningPushSum = 0;
  let rangeSum = 0;

  results.dailyData = data.map((d) => {
    let gap = +(
      ((d.OpenPrice - d.PreviousClose) / d.PreviousClose) *
      100
    ).toFixed(2);
    let morningPush = +(
      ((d.HighPrice - d.OpenPrice) / d.OpenPrice) *
      100
    ).toFixed(2);
    let range = +(((d.HighPrice - d.LowPrice) / d.LowPrice) * 100).toFixed(2);

    gapSum += gap;
    morningPushSum += morningPush;
    rangeSum += range;

    return {
      ...d,
      gap,
      morningPush,
      range,
    };
  });

  results.averageData = {
    avgGap: (gapSum / data.length).toFixed(2),
    avgMorningPush: (morningPushSum / data.length).toFixed(2),
    avgRange: (rangeSum / data.length).toFixed(2),
  };

  return results;
};
